@import "~material-icons/iconfont/material-icons";
@import "header";
@import "form_preview";
@import '~bootstrap/scss/bootstrap';


*:focus {
  outline: 0;
}

body {
  background: #f9f9f9 !important;
}

.daily_preview_form {
  background: white;
  padding: 35px;
  margin-top: 35px;
  border-radius: 6px;

  div.form-group {
    label.form-label {
      font-weight: bolder;
    }

    margin-bottom: 25px;
  }
}

.formheader-tabs {
  .nav-item {
    a {

    }

    a.active {
      //border-bottom: 3px solid $primary;
      // color: $primary;
    }
  }
}

.addFormCard {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 3px dashed $primary;
  cursor: pointer;

  .inner {
    display: flex;
    align-items: center;

    span.material-icons {
      color: $primary;
      margin-right: 15px;
      font-size: 41px;
    }

    span.addDescription {
      color: $primary;
    }
  }
}


.fullformpage {
  background: transparent;
  position: relative;
  top: 0;
  display: block;
  margin: 0;
  padding: 0;
  overflow: auto;
  min-height: 100vh;

  .container {
    background: white;
    min-height: 100vh;
    padding: 50px 40px 0;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    margin-top: 25px;
    max-width: 960px;
  }

  .form-group {
    margin-bottom: 2rem;
  }

}

.ql-editor {
  min-height: 5em * 1.8;
}


.formheader-tabs {
  .nav-link {
    background: rgba(0, 0, 0, 0.15);
    margin: 0 1em;

    span {
      color: rgba(0, 0, 0, 0.5)
    }

    &.active {
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.13);

      span {
        color: black !important;
      }
    }
  }
}

.addNewItemButton {
  width: 50%;
  min-width: 320px;
}

label.form-label {
  font-weight: 600;
  //text-decoration: underline;
}

.settings-section {
  margin-bottom: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 1em;
  background: white;

  .settings-section-label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1em;
  }

  .settings-section-content {

  }
}
