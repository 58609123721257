.header {
  display: flex;
  flex-direction: column;

  .header_upper {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    background: white;
    align-content: center;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.4);

    #logo{
      display:flex;
      align-items:center;
      margin-right:3em;

      .logo-inner{
          font-size:1.5em;
        line-height: 1.5em;
        font-weight: bolder;
      }
    }

  }

  .header_extra {

  }
}

.formheader-tabs {

  .nav-item {

  }

  a.nav-link {

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;

    span {
      display: block;
      &.material-icons{
        margin-right:5px;
      }
    }

  }
}
