.form-preview-item {
  display: flex;
  flex-direction: column;
  //border: 1px solid #dadce0;
  padding: 0;
  margin-bottom: 50px;
  border-radius: 8px;
  background: white;
  overflow:hidden;
  box-shadow: 0 0 1px 0px rgba(0,0,0,0.5);
}

.edit-actions{
  padding: 5px 15px 15px;
}


.form-edit-item-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;


  .indicator{
    width:8px;
    background: #8caeff;
  }

  .form-preview-item-body {
    flex: 1 1 auto;
    padding: 5px 15px 15px;
    margin-top: 25px;
  }

}

.form-preview-item-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  padding: 5px 15px 15px;

  .form-preview-item-body {
    flex: 1 1 auto;
  }

  .form-preview-item-actions {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    width: 50px;
    border-left: 1px solid #ddd;
    cursor: pointer;
    .form-preview-item-edit {
      display: flex;
      flex-direction: column;
      justify-content: center;

      align-items: center;

      .material-icons {
        color: #999;
      }

      .label {
        color: #999;
        font-size: 10px;
      }
    }
  }

}





.draggo {
  background-color: transparent;
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  cursor: grab;
  position: absolute;

  span {
    color: #888;
    transform: rotate(90deg);
  }
}
